$color_1: black;
$color_2: white;

.movie-card {
	margin-bottom: 65px;
	box-shadow: 8px 5px 5px darkgray;
	width: 21%;
	z-index: 998;
	&:hover {
		.delete-btn {
			display: block;
			background: #f5f5f5;
			&:hover {
				display: block;
				background: rgb(228, 48, 48);
				border: 1px solid #f5f5f5;
				color: $color_2;
			}
		}
	}
}
.delete-btn {
	bottom: 10px;
	display: none;
	position: absolute;
	right: 10px;
	z-index: 999;
	cursor: pointer;
	border-radius: 5px;
	background: lightgray;
	color: $color_1;
	border: 1px solid darkgray;
}
.grow {
	transition: all .2s ease-in-out;
	&:hover {
		transform: scale(1.1);
	}
}
img {
	height: 100%;
	width: 100%;
	object-fit: cover;
}
#movie-img {
	width: 100%;
	height: 100%;
}
@media(max-width: 500px) {
	.movie-card {
		width: 40%;
		overflow: hidden;
	}
}

