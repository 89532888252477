 $background_color_1: #f5f5f5;

.container {
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: center;
	flex-wrap: wrap;
	background-color: $background_color_1;
}
.login-inputs {
	height: 70vh;
	display: flex;
	justify-content: space-evenly;
	flex-direction: column;
	align-items: center;
	flex-wrap: wrap;
	width: 50vw;
	background: white;
	box-shadow: 5px 10px 10px #888888;
	input {
		font-size: 15px;
		border: 0;
		outline: 0;
		background: transparent;
		border-bottom: 1px solid lightgray;
		cursor: pointer;
	}
}
.login-btns {
	border: 0;
	outline: 0;
	background: transparent;
	border-bottom: 1px solid lightgray;
	cursor: pointer;
}
.login-container {
	display: flex;
	justify-content: space-around;
	width: 40%;
}
.divider {
	width: 75px;
	height: auto;
	display: inline-block;
}
@media(max-width: 1024px) {
	.login-inputs {
		height: 50vh;
		width: 50vw;
	}
}
@media(max-width: 750px) {
    .login-container {
        display: flex;
        justify-content: space-around;
        width: 49%;
    }
}
@media(max-width: 500px) {
	.login-inputs {
		height: 45vh;
		width: 85vw;
	}
}